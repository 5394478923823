/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */

@import "./app/app.scss";

@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

body.swal2-height-auto {
  min-height: 100vh !important;
}

// placed here instead of tutorial.scss due to slide els not getting ng scoping attribute
.swiper-slide {
  display: block;
}

.sc-ion-alert-md-h {
  --min-width: 350px;
  --width: auto;
  --min-height: auto;
  --height: auto;
  --max-height: 90%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: none;
  font-family: var(--ion-font-family, inherit);
  contain: strict;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1001;
}

.bg-primary {
  background-color: var(--ion-color-primary);
}

#filterOptions {
  width: 100%;
  border-radius: 14px;
  padding: 5px 10px;
  border: 1px solid gray;
}

#filterOptions:focus {
  outline: none;
  border: 1px solid dodgerblue;
}

#filterOptions-parent {
  margin-bottom: -20px;
}