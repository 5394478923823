/* Ionic CSS Variables */
:root {
  /* primary */
  --ion-color-primary: #00184E;
  --ion-color-primary1: #C6F8CB;
  --ion-color-primary-rgb: 79,36,172;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #0d4db9;
  --ion-color-primary-tint: #0b4bb5;


  /* secondary */
  --ion-color-secondary: 	#0b4bb5;
  --ion-color-secondary1: #018abd;
  --ion-color-secondary-rgb: 152,108,218;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: 	#10439f;
  --ion-color-secondary-tint: #194ca8;

  /* tertiary */
  --ion-color-tertiary: #D6D5EF;
  --ion-color-tertiary1: #97cbdc;
  --ion-color-tertiary-rgb: 214,213,239;
  --ion-color-tertiary-contrast: 	rgb(3, 40, 102);
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #bcbbd2;
  --ion-color-tertiary-tint: #dad9f1;

  /* success */
  --ion-color-success: #15DA92;
  --ion-color-success1: #97cbdc;
  --ion-color-success-rgb: 93,200,152;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #52b086;
  --ion-color-success-tint: #6dcea2;

  /* warning */
  --ion-color-warning: #e0b910;
  --ion-color-warning-rgb: 254,210,18;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #FED212;
  --ion-color-warning-tint: #fed72a;

  /* danger */
  --ion-color-danger: #EF291D;
  --ion-color-danger1: #6a0000;
  --ion-color-danger-rgb: 239,41,29;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d2241a;
  --ion-color-danger-tint: #f13e34;

  /* dark */
  --ion-color-dark: #004581;
  --ion-color-dark-rgb: 37,13,91;
  --ion-color-dark-contrast: #D6D5EF;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: rgb(7, 53, 132);
  --ion-color-dark-tint: 	rgb(3, 37, 98);

  /* medium */
  --ion-color-medium: #B2BED5;
  --ion-color-medium-rgb: 178,190,213;
  --ion-color-medium-contrast: rgb(3, 40, 102);
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #9da7bb;
  --ion-color-medium-tint: #bac5d9;

  /* light */
  --ion-color-light: #F7F7FA;
  --ion-color-light-rgb: 247,247,250;
  --ion-color-light-contrast: rgb(3, 40, 102);
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d9d9dc;
  --ion-color-light-tint: #f8f8fb;

}